@media print {
  footer,
  nav,
  aecom-header,
  aecom-left-nav {
    display: none;
  }

  .app-body {
    padding-top: 0px !important;
    height: auto;
    overflow: hidden;
  }

  .no-print {
    display: none !important;
  }

  .report-margin {
    margin: 0px !important;
    padding-top: 0px !important;
  }

  .no-print-margin {
    margin: 0px !important;
    width: 612pt !important;
    padding: 0px !important;
  }

  body {
    zoom: 100%;
  }

  @page {
    margin: 0;
    size: 612pt 792pt;
  }
}

@page {
  margin: 0;
  size: 612pt 792pt;
}

@import "node_modules/@aecom/core/src/assets/styles/core.scss";
@import "./icons.scss";

.content {
  overflow: hidden;
}

body {
  font-family: "urw-din-semi-condensed", "Barlow Semi Condensed", sans-serif;
  background-color: #fff;
}

a {
  text-decoration: none !important;
}

.uppercase {
  text-transform: uppercase;
}

#sidebarwrapper {
  position: fixed;
  z-index: 2;
  left: 0px;
  top: 40px;
  bottom: 0;
  background-color: #111;
}

.app-left-nav .wrapper {
  width: 100%;
  overflow: hidden;
}

.appLogo {
  mask: url("./../assets/img/appLogo.svg") no-repeat 50% 50%;
  background-color: #fff;
  width: 159px;
  height: 16px;
}

.browser .node.root-node,
.nav-browser .children .child-node {
  text-transform: capitalize;
}

.app-left-nav,
.app-header .button.show,
.app-header .button:hover {
  background-color: #111;
}


.form-item {
  margin: 0;
}

.btn-tab {
  width: 100%;
  height: 40px;
  color: #000;

  &.bg-grey-e {
    color: #999;
  }
}

.noItem-body {
  margin: auto;
}

.noItem-body > div {
  width: 100%;
  text-align: center;
}

.noItem-icon-row {
  width: 60px !important;
  height: 60px !important;
}

.noItem-title-row {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #aaaaaa;
  margin-top: 20px;
}

.noTableFilter .table-container .filters-wrapper {
  display: none;
}

.listRFI_tr th {
  color: #777777 !important;
}

.selectUserTable .table-container {
  background-color: rgba(255, 255, 255, 0) !important;
}

.header-actions .unchange {
  pointer-events: none;
}

.cusTab .tab-control .tabs .tab.selected {
  border-bottom: none !important;
  color: #000 !important;
  background-color: rgba(0, 181, 226, 0.1) !important;
  min-width: 200px !important;
}

.cusTab .tab-control .tabs {
  border-bottom: 1px solid #00b5e2 !important;
}

.cusTab .tab-control .tabs .tab {
  border-bottom: none !important;
  color: #999 !important;
  min-width: 200px !important;
}

.cusTab .tab-control .tabs .tab:hover {
  background-color: rgba(0, 181, 226, 0.1) !important;
  color: #000 !important;
}

.itemBlock label {
  font-style: normal;
  font-weight: 400;
  font-size: 17px !important;
  line-height: 20px;
  color: #777777;
}

.matchToTab .form-group .header {
  height: 32px;
}

.title-icon-purple {
  background-color: #9b247f;
}


.lightFormGroup .form-group-header span {
  color: #000 !important;
}

.form-group-header {
  padding: 4px 0;
  height: 32px;
}

.btn.solid.sm {
  text-transform: none;
  min-width: 62px;
  height: auto;
  font-size: 14px;
  color: #fff;
  padding: 3px 0 !important;
  border-radius: 0;
  line-height: 1;
}

.mat-progress-bar-buffer {
  background-color: #ddd;
}
.mat-progress-bar-fill::after {
  background-color: #9B247F;
}

.input-checkbox span {
  color: #777777 !important;
}
.form-group-header {
  margin-top: 0!important;
}
.bbj_onboarding_icon {
  background-color: #00b5e2;
  mask: url("../assets/icons/bbj_onboarding_icon.svg") no-repeat 50% 50%;
}

.bbj_product_icon_settings {
  background-color: #BE2150;
  mask: url("../assets/icons/bbj_product_icon_settings.svg") no-repeat 50% 50%;
}
.icon-action_app_support {
  background-color: #00b5e2;
}

.altBottonColor {
  background-color: #ADC6CC;
}

.hideHeader .form-group-header {
  display: none;
}